.ff-sans-serif {
    font-family: $ff-sans-serif;
}
body {
    background: radial-gradient(circle, rgba(247,248,248,1) 20%, rgb(183, 218, 192) 100%);
    font-family: 'Typo Round';
    color: #494949;
}
.page-content {
    padding: 0;
    // flex: 0;
}
.site-footer {
    border-top: 0;
}