@keyframes cd-bounce-left {
    0% {
      opacity: .2;
      transform: translateX(200px);
    }
    60% {
      opacity: .7;
      transform: translateX(-15px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
}
@keyframes cd-bounce-right {
    0% {
        opacity: .2;
        transform: translateX(-200px);
    }
    60% {
        opacity: .7;
        transform: translateX(15px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes cd-bounce-up {
    0% {
        opacity: .2;
        transform: translateY(200px);
    }
    60% {
        opacity: .7;
        transform: translateY(15px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes cd-bounce-down {
    0% {
        opacity: .2;
        transform: translateY(-200px);
    }
    60% {
        opacity: .7;
        transform: translateY(15px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
#home-container {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background: radial-gradient(circle, rgba(247,248,248,1) 20%, rgb(183, 218, 192) 100%);
    animation: fade 1.5s ease forwards;
    .background {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-size: 1050px auto;
        background: transparent url('/assets/images/background-single.png') 0 0 no-repeat;
        background-size: contain;
        width: 350px;
        height: 300px;
        @include media-breakpoint-up(md) { 
            width: 1000px;
        }
       &.bottom-right {
            left: auto;
            right: 0;
            top: auto;
            transform: scale(-1);

        }
    }
    .btn-primary {
        color: white;
        background: rgb(79,144,129);
        background: linear-gradient(0deg, rgba(79,144,129,1) 0%, rgba(125,223,195,1) 100%);
        border: 0;
        font-size: 1rem;
        position: fixed;
        top: 1rem;
        right: 1.5rem;
        left: 1.5rem;
        text-decoration: none;
        opacity: 0;
        animation-delay: 1s;
        animation-fill-mode: forwards;
        animation-duration: 1s;
        animation-name: cd-bounce-down;
        &:hover,&:active {
            background: rgb(77,77,77);
            background: linear-gradient(0deg, rgba(77,77,77,1) 0%, rgba(125,125,125,1) 100%);
        }
        @include media-breakpoint-up(md) { 
            top: 1rem;
            right: 1rem;
            left: auto;
            font-size: 1.25rem;
        }
    }
    #header-logo {
        margin-left: -2.5rem;
        img {
            opacity: 0;
            animation-delay: .25s;
            animation-fill-mode: forwards;
            animation-duration: 2s;
        }
        .logo-mark {
            animation-name: cd-bounce-right;
            width: 10rem;
            margin: auto;
            @include media-breakpoint-up(md) { 
                width: 15rem;
                margin: 0;
            }
        }
        .logo-text {
            animation-name: cd-bounce-left;
            width: 8rem;
            margin-left: -1.5rem;
            @include media-breakpoint-up(md) { 
                margin-left: -1.5rem;
                width: 10rem;
            }
        }
        @include media-breakpoint-up(md) { 
            margin-left: -2.5rem;
        }
    }
    #header-copy {
        opacity: 0;
        animation-delay: 1s;
        animation-fill-mode: forwards;
        animation-duration: 1s;
        animation-name: cd-bounce-up;
    }
    h2 {
        margin-top: -1rem;
        font-weight: bold;
        letter-spacing: -0.05rem;
        @include media-breakpoint-up(md) { 
            margin-top: -1.25rem;
        }
    }
    .contact-link {
        font-size: 1.45rem;
        text-decoration: none;
        margin: 0 1rem;
        color: #3c987b;
        transition: all 300ms ease;
        position: relative;
        display: block;
        &:hover, &:active {
            color: #494949;
        }
        &:after {
            content: '';
            position: absolute;
            right: -23px;
            width: 8px;
            height: 8px;
            background: #50bfa8;
            border-radius: 50%;
            top: 10px;
            display: none;
            @include media-breakpoint-up(md) { 
                display: block;
            }
        }
        &:last-child:after {
            display: none;
        }
        @include media-breakpoint-up(md) { 
            display: inline-block;
        }
    }
}