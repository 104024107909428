@font-face {
    font-family: 'Typo Round';
    src: url('/assets/fonts/typo_round_regular.woff2') format('woff2'),
         url('/assets/fonts/typo_round_regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
$primary: #0074d9;
$body-bg: rgb(247,248,248);
$btn-border-radius: 2rem;
$ff-sans-serif: BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";