/* Fade out current container */
.fade-leave {
  opacity: 1;
}

.fade-leave-active {
  transition: opacity 0.3s linear;
}

.fade-leave-to {
  opacity: 0;
}

/* Fade in the next container */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.3s linear;
}

.fade-enter-to {
  opacity: 1;
}

.fade-once {
  opacity: 0;
  // background: red;
}

.fade-once-active {
  transition: all 1s linear;
}

.fade-once-to {
  opacity: 1;
  // background: pink;
}